.u-parts__button{
  border: 1px solid #fff;
  text-align: center;
  @include max(){
    width: 33%;
    padding: calc_rem(12) 0;
  }
  @include min(){
    @include font_rem(22);
    padding: calc_rem(5) calc_rem(52);
  }
}



.text{
  &-center{
    text-align: center;
  }
}