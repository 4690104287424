/* FONT FACE
------------------------------------------------------ */
@font-face {
  font-family: 'Futura';
  src: url('../fonts/Futura Book font.ttf') format('truetype');
  font-weight: 400;
}

.font-futura{
  font-family: 'Futura', sans-serif;
}

@mixin futura(){
  font-family: 'Futura', sans-serif;
  font-weight: 400;
}