/* fade
------------------------------------------------------ */
@include keyframes("fade-in"){
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@include keyframes("fade-out"){
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
.key-fadein {
  animation : fade-in 1s;
  animation-fill-mode: both;
}
.key-fadeout {
  animation : fade-out 1s;
  animation-fill-mode: both;
}

/* 縦スクロールのアニメーション
------------------------------------------------------ */
// 線が上から下に動く
@keyframes pathmove {
  0% {
    height: 0;
    top: 0;
    opacity: 0;
  }
  30% {
    height: 50px;
    opacity: 1;
  }
  100% {
    height: 0;
    top: 100px;
    opacity: 0;
  }
}
