// レイアウト
$break: 751;

// PC パーセント計算基準値
$per_pc: 1920;
// SP パーセント計算基準値
$per_sp: 750;

// ベース色
$color-key: #efa336;
$color-accent: #e65100;
$color-background: #F9FAE2;
$color-text: #222;
$color-text--thin: #757575;

// 透過度
$opacity: 0.6;

//フォント
$font-family-hirakaku3: "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "メイリオ", meiryo, "MS Pゴシック", "MS PGothic", sans-serif;
$font-yu-go: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "MS Pゴシック", "MS PGothic", sans-serif;

$font_normal: $font-family-hirakaku3;
$font-size-base: 16;
$font-size-base-sp: 11;
$font-weight-base: 500;
$line-height-base: 1.6;
