/*
 * メディアクエリ
---------------------------------------*/
@mixin min($breakpoint: $break + px) {
  @media print, screen and (min-width: $breakpoint){
    @content;
  }
}
@mixin max($breakpoint: ($break - 1) + px) {
  @media screen and (max-width: $breakpoint){
    @content;
  }
}
// 基準サイズ以上のブラウザ幅の場合
@mixin maxQuery() {
  @media print, screen and (min-width: #{$width_large}px){
    @content;
  }
}

/*
 * fontawesome
---------------------------------------*/
@mixin fontawesome(){
  font-family: $font-awesome;
  font-weight: 900;
}

/*
 * keyframes設定
---------------------------------------*/
@mixin keyframes( $animation_name) {
  @-webkit-keyframes #{$animation_name} {
    @content;
  }
  @-moz-keyframes #{$animation_name} {
    @content;
  }  
  @keyframes #{$animation_name} {
    @content;
  }
}
// アニメーションの設定
@mixin animation( $animation_name, $duration: 1.2s, $count: infinite, $timing: ease-in-out, $delay: 0.15s ) {
  -webkit-animation: $animation_name $duration $count $timing $delay;
  -moz-animation: $animation_name $duration $count $timing $delay;
  animation: $animation_name $duration $count $timing $delay;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
}

/*
 * flexの設定
---------------------------------------*/
@mixin flex($justify:0, $align:0, $wrap:0, $wrap_align:0, $column:0) {
  display: flex;

  // justify-content水平方向揃え（第一引数）
  @if $justify == start { // 左揃え
    justify-content: flex-start;
  } @else if $justify == end { // 右揃え
    justify-content: flex-end;
  } @else if $justify == center { // 中央揃え
    justify-content: center;
  } @else if $justify == between { // 両端配置
    justify-content: space-between;
  } @else if $justify == around { // 均等配置
    justify-content: space-around;
  }

  // align-items垂直方向揃え（第二引数）
  @if $align == start { // 上揃え
    align-items: flex-start;
  } @else if $align == end { // 下揃え
    align-items: flex-end;
  } @else if $align == center { // 中央揃え
    align-items: center;
  } @else if $align == baseline { // ベースライン
    align-items: baseline;
  } @else if $align == stretch { // 高さ揃え
    align-items: stretch;
  }

  // flex-wrap子要素の折り返し設定（第三引数）
  @if $wrap == nowrap { // 折り返ししない
    flex-wrap: nowrap;
  } @else if $wrap == wrap { // 上から下
    flex-wrap: wrap;
  } @else if $wrap == reverse { // 下から上
    flex-wrap: wrap-reverse;
  }

  // align-content複数行設定（第四引数）
  @if $wrap_align == start { // 上揃え
    align-content: flex-start;
  } @else if $wrap_align == end { // 下揃え
    align-content: flex-end;
  } @else if $wrap_align == center { // 中央揃え
    align-content: center;
  } @else if $wrap_align == between { // 両端配置
    align-content: space-between;
  } @else if $wrap_align == around { // 均等配置
    align-content: space-around;
  } @else if $wrap_align == stretch { // 高さ揃え
    align-content: stretch;
  }

  // 縦並び（第五引数）
  @if $column == 1 {
    flex-direction: column;
  }
}


/*
 * transitionの初期設定
---------------------------------------*/
@mixin transition($key:all, $speed:0.3s, $timing:ease-in-out) {
  transition: $key $speed $timing;
}

// absolute一括設定 
@mixin absolute($top:null, $left:null, $bottom:null, $right:null){
  position: absolute;
  @if $top != null{
    top: $top;
  }
  @if $right != null{
    right: $right;
  }
  @if $bottom != null{
    bottom: $bottom;
  }
  @if $left != null{
    left: $left;
  }
}

/*
 * 背景画像縦横比
---------------------------------------*/
@mixin aspect_ratio($width, $height, $image_path: null) {
  display: block;
  width: 100%;
  height: 0;
  padding-top: math.div($height, $width) * 100%;
  @if $image_path != null {
    background: url($image_path) 50% 50% no-repeat;
    background-size: cover;
  }
}

/*
 * テキスト上下の余白をなくす
---------------------------------------*/
@mixin text_crop($font-size: 21, $line-height: 1.5, $top-adjustment: 0, $bottom-adjustment: 0) {
  $margin: (($font-size * $line-height) - $font-size) / 2;
  // Mixin output
  font-size: $font-size + px;
  line-height: $line-height;
  &::before,
  &::after {
      content: '';
      display: block;
      height: 0;
      width: 0;
  }
  &::before { margin-bottom: calc(-#{$margin}px + #{$top-adjustment}px); }
  &::after { margin-top: calc(-#{$margin}px + #{$bottom-adjustment}px); }
}

/*
 * width指定時に中央配置
---------------------------------------*/
@mixin width($width: 100%, $max: 100%){
  width: $width;
  max-width: $max;
  margin-left: auto;
  margin-right: auto;
}

/*
 * フォントサイズvw調整
---------------------------------------*/
@mixin font_vw($min-font: 18, $max-font: 40, $min-width: 375, $max-width: 750){

  font-size: #{$min-font}px;
  font-size: calc_vw($min-font, $max-font, $min-width, $max-width);

}

// vw計算function
@function calc_vw($min-font: 18, $max-font: 40, $min-width: 375, $max-width: 750){

  // 計算式 ：calc(1rem + ((1vw - XXpx) * YY))
  // XX : ViewPort の最小幅 / 100 
  $xx : math.div($min-width, 100);
  // yy = 100 * フォントサイズの差 / ViewPort 幅の差
  $font-diff : $max-font - $min-font;
  $wid-diff : $max-width - $min-width;
  $yy :  math.div(100 * $font-diff, $wid-diff);

  @return calc( #{$min-font}px + ((1vw - #{$xx}px) * #{$yy} ) );

}

/*
 * フォントサイズrem指定
---------------------------------------*/
@mixin font_rem($size, $device: "pc"){

  font-size: $size + px;
  font-size: calc_rem($size, $device);
}

@function calc_rem($size, $device: "pc"){
  // base size計算
  $base : $font-size-base;
  @if $device == "sp"{
    $base: $font-size-base-sp * 2;
  }

  @return math.div($size, $base) + rem;
}

/*
 * フォントサイズrem指定
---------------------------------------*/
@function set_per($size, $device: "pc"){
  $base : $per_pc;
  @if $device == "sp"{
    $base: $per_sp;
  }
  @return math.div($size, $base) * 100%;
}

/*
 * flex-boxのitem幅
---------------------------------------*/
@mixin flex_item($device: ""){
  @if $device != ""{
    $device : "-" + $device;
  }

  .flex_item {
    &#{$device}-col {
      @for $i from 1 through 12 {
        &#{$i} {
          width: (math.div(100, 12) * $i) - 2 * 1%;
        }
      }
    }
  }
}
