.l-page__wrapper{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.l-main{
  position: relative;
  overflow: hidden;
  flex: 1;
  overflow-x: hidden;
  margin-top: calc_rem(145);
}

.l-container{
  @include max(){
    padding-left: calc_vw(15, 30);
    padding-right: calc_vw(15, 30);
  }
  @include min(){
    @include width(100%, 1690px);
    padding: 0 40px;
  }
  @include min(830px){
    padding: 0 80px;
  }
}

.l-container-fluid{
  margin: 0 calc(50% - 50vw);
  width: 100vw;
}

.l-overlay{
  @include absolute(0, 0);
  width: 100%;
  height: 100%;
  &:after{
    @include absolute(50%, 50%);
    transform: translate(-50%, -50%);
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
}