.l-footer{
  background-color: #404040;
  color: #F9FAE2;
  @include max(){
    padding-top: calc(83 / 750 * 100%);
  }
  @include min(){
    padding-top: calc_rem(60);
  }
  .footer__info{
    @include max(){
      text-align: center;
    }
    @include min(){
      @include flex(between);
      max-width: calc_rem(738);
      margin-left: auto;
      margin-right: auto;
    }
    img{
      @include max(){
        width: 15.5%;
        margin-top: calc(50 / 750 * 100%);
      }
    }
  }
  .footer__nav{
    @include min(){
      @include flex(between);
    }
    img{
      width: 150px;
    }
  }
  .footer__menu{
    + .footer__menu{
      @include min(){
        margin-left: calc_rem(98);
      }
    }
    .footer__menu__item{
      position: relative;
      @include futura;
      color: #F9FAE2;
      @include max(){
        text-align: center;
      }
      .footer__menu__sub{
        @include max(){
          //display: none;
        }
        @include min(){
          //padding: calc_rem(10) 0 calc_rem(34);
          transition: all 0.3s ease;
          z-index: -1;
        }
        .footer__menu__sub__item{
          @include max(){
            @include font_vw(20, 40);
            letter-spacing: 0.15em;
          }
          @include min(){
            @include font_rem(14);
            letter-spacing: 0.2em;
          }
          &.nodrop{
            a{
              cursor: no-drop;
            }
          }
          a{
            @include max(){
              font-size: 0.5em;
              line-height: 1.5;
              padding: calc_rem(15) 0 calc_rem(1);
            }
            @include min(){
              line-height: 1.0;
            }
          }
        }
      }
      a, p{
        @include futura();
        @include max(){
          @include font_vw(13, 26);
          line-height: calc(70 / 26 * 1em);
          letter-spacing: 0.07em;
        }
        @include min(){
          @include font_rem(16);
          line-height: 60px;
          letter-spacing: 0.07em;
        }
      }
      &.sub{
        &:after{
          /* @include max(){
            content: "";
            position: absolute;
            top: 1.25em;
            right: 23%;
            display: inline-block;
            width: calc_vw(6, 13);
            height: calc_vw(13.5, 27);
            background-image: url(../images/icon_menu_arrow_white.svg);
            background-repeat: no-repeat;
            background-size: contain;
          } */
        }
      }
      &:hover{
        @include min(){
          .footer__menu__sub{
          opacity: 1;
          transform: translateY(0);
          }
        }
      }
    }
  }
  .footer__copy-wrapper{
    text-align: center;
    @include max(){
      padding-top: calc(100 / 750 * 100%);
      padding-bottom: calc(100 / 750 * 100%);
    }
    @include min(){
      padding: set_per(30) 0;
    }
  }
  .footer__copy{
    font-family: $font-yu-go;
    @include font_vw (10, 20);
    letter-spacing: 0.15em;
    @include min(){
      @include font_rem(14);
      letter-spacing: 0.15em;
    }
  }
}