.u-icon__bg--before{
  position: relative;
  &:before{
    content: "";
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.u-icon__address{
  min-height: calc_rem(21.98);
  &:before{
    width: calc_vw(12.73, 25.46);
    height: calc_vw(18.11, 36.22);
    background-image: url(../images/icon-placeholder.svg);
    @include min(){
      width: calc_rem(15.45);
      height: calc_rem(21.98);
    }
  }
}

.u-icon__tel{
  &:before{
    width: calc_vw(17.725, 35.45);
    height: calc_vw(17.79, 35.58);
    background-image: url(../images/icon-telephone.png);
    @include min(){
      width: calc_rem(21.51);
      height: calc_rem(21.6);
    }
  }
}

.u-icon__mail{
  &:before{
    width: calc_vw(17.725, 35.45);
    height: calc_vw(17.79, 35.58);
    background-image: url(../images/icon-mail.png);
    @include min(){
      width: calc_rem(21.51);
      height: calc_rem(21.6);
    }
  }
}