/* フェード用CSS
------------------------------------------------------ */
.js-fade-in {
  opacity: 0;
  transition-duration: 2.5s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
}

.js-fade-outQuit{
  transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
}
.js-fade-in-up {
  transform: translate3d(0, 50px, 0);
}

.js-fade-in-down {
  transform: translate3d(0, -100px, 0);
}

.js-fade-in-left {
  transform: translate3d(-50vw, 0, 0);
}

.js-fade-in-right {
  transform: translate3d(50vw, 0, 0);
}

.js-scroll-in {
  opacity: 1 !important;
  transform: translate3d(0, 0, 0)!important;
}
