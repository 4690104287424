.l-header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #F9FAE2;
  z-index: 2;
  @include min(){
    //padding-top: calc_rem(70);
    padding-top: calc_rem(13);
  }
  .header__inner{
    @include max(){
      position: relative;
      z-index: 10;
      background-color: #F9FAE2;
      padding-top: calc(19 / 750 * 100%);
      padding-bottom: calc(20 / 750 * 100%);
    }
  }
  .header__logo{
    position: relative;
    margin-left: auto;
    margin-right: auto;
    z-index: 10;
    @include max(){
      width: 20%;
      max-width: calc_rem(120);
    }
    @include min(){
      width: calc_rem(80 * 1.2 * 1.1 * 1.1);
    }
  }
  img{
    width: auto;
  }
}

.header__menu{
  @include max(){
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #F9FAE2;
    z-index: 2;
    padding-top: calc(126 / 750 * 100%);
    padding-bottom: 8%;
    overflow-y: scroll;
  }
}
.header__menu1{
  @include min(){
    max-width: calc_rem(760);
    padding: calc_rem(9) 0 calc_rem(13);
    margin-left: auto;
    margin-right: auto;
  }

  .header__menu__logo{
    margin-top: calc_rem(40, "sp");
    width: 33%;
    margin-left: auto;
    margin-right: auto;
    img{
      width: 100%;
    }
  }
  .header__menu__list{
    background-color: #F9FAE2;
    @include max(){
      text-align: center;
      padding-top: 8%;
    }
    @include min(){
      @include flex(between, center);
    }
    .header__menu__item{
      position: relative;
      @include futura();
      line-height: 2;
      letter-spacing: 0.06em;
      color: $color-text;
      
      @include max(){
        @include font_vw(18, 38);
        letter-spacing: 0.15em;
        line-height: 2.5;
      }
      @include min(){
        @include font_rem(16);
      }
      &:after{
        @include min(){
          content: "";
          display: block;
          width: 0;
          transition: 1.0s cubic-bezier(0.23, 1, 0.32, 1);
          border-bottom: 1px solid #222;
        }
      }
      &:hover:after{
        width: 100%;
      }
      + .header__menu__item{
        @include max(){
          margin-top: 1%;
        }
        @include min(){
          letter-spacing: calc_rem(1.5);
          margin-left: calc_rem(6);
        }
      }
      &.reservation{
        &:after{
          content: none;
        }
        a{
          &:hover{
            background-color: #fff;
            color: #3B302B;
          }
        }
      }
      &.sub{
        &:after{
        /*   @include max(){
            content: "";
            position: absolute;
            top: 1.25em;
            right: 23%;
            display: inline-block;
            width: calc_vw(6, 13);
            height: calc_vw(13.5, 27);
            background-image: url(../images/icon_menu_arrow.svg);
            background-repeat: no-repeat;
            background-size: contain;
          } */
        }
      }
      &:hover{
        @include min(){
          .header__menu__sub{
          opacity: 1;
          transform: translateY(0);
          }
        }
      }
    }
    .header__menu__sub{
      @include max(){
        //display: none;
      }
      @include min(){
        position: absolute;
        left: calc_rem(10);
        opacity: 0;
        background-color: #fff;
        transform: translateY(-50%);
        padding: calc_rem(10) 0 calc_rem(34);
        transition: all 0.3s ease;
        z-index: -1;
      }
      .header__menu__sub__item{
        @include max(){
          @include font_vw(20, 40);
          letter-spacing: 0.15em;
        }
        @include min(){
          @include font_rem(14);
          letter-spacing: 0.2em;
          width: calc_rem(200);
        }
        &.nodrop{
          a{
            cursor: no-drop;
          }
        }
        a{
          padding: calc_rem(15) 0 calc_rem(1);
          @include max(){
            font-size: 0.75em;
            line-height: 1.5;
          }
          @include min(){
            padding: calc_rem(15) 0 calc_rem(1) calc_rem(21);
          }
          &:hover{
            opacity: 0.6;
          }
        }
      }
    }
    a, p{
      display: block;
      cursor: pointer;
      @include min(){
        @include font_rem(14);
        padding: calc_rem(4) calc_rem(4);
      }
    }
  }
}
.header__menu2{
  @include min(){
    position: absolute;
    top: calc_rem(38);
    right: calc_vw(20, 190, 768, 1920);
  }
  @include min(1920px){
    top: calc_rem(38);
    right: calc_rem(190);
  }
  .menu__reservation{
    @include futura();
    @include font_rem(16);
    letter-spacing: 0.07em;
    @include flex(center, center);
    text-align: center;
    border: 1px solid #222;
    color: $color-text;
    @include max(){
      @include font_vw(17, 34);
      width: 51%;
      border-width: 1px;
      padding-top: calc(19 / 750 * 100%);
      padding-bottom: calc(19 / 750 * 100%);
      margin-top: calc(53 / 750 * 100%);
      margin-left: auto;
      margin-right: auto;
    }
    @include min(){
      @include font_rem(14);
      width: calc_rem(171 * 0.7);
      height: calc_rem(50 * 0.6);
      margin-left: calc_rem(32);
    }
  }
  .menu__language{
    @include max(){
      margin-top: calc(43 / 750 * 100%);
    }
  }
  .menu__language, .menu__language a{
    @include futura();
    @include max(){
      @include font_vw(17, 34);
      text-align: center;
      letter-spacing: 0.3em;
    }
    @include min(){
      @include font_rem(14);
      letter-spacing: 0.1em;
    }
  }
}

/* SPメニューアイコン */
.sp_menu_icon{
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1002;
  padding-top: calc(40 / 750 * 100%);
  padding-right: 7.5%;
  margin-left: auto;
  margin-right: 0;
  overflow: hidden;

  .menu{
    display: none;
    &:checked{
      +.menu_label .memu_border{
        &:first-child{
          top: 45%;
          left: -8%;
          width: 110%;
          transform: rotate(45deg);
        }
        &:nth-child(2){
          transform: translate3d(200%, 0, 0);
        }
        &:last-child{
          top: 43%;
          left: -8%;
          width: 110%;
          transform: rotate(-45deg);
        }
      }
    }
  }
  .menu_label{
    position: relative;
    display: block;
    width: calc_vw(35 * 0.9, 55 * 0.9);
    height: calc_vw(30 * 0.9, 60 * 0.9);
    margin: 0;
  }
  .memu_border,
  .memu-border:before,
  .memu-border:after{
    @include absolute($left: 60%);
    transform: translateX(-50%);
    height: calc_vw(2.5, 5);
    width: 100%;
    background-color: #222;
    @include transition(all, 0.3s, cubic-bezier(0.785, 0.135, 0.15, 0.86));
    will-change: transform;
    backface-visibility: hidden;
  }
  .memu_border{
    &:first-child{
      top: 4%;
    }
    &:nth-child(2){
      top: 25%;
    }
    &:last-child{
      top: 46%;
    }
  }

  @include min(){
    display: none;
  }
}
