/* 右から左に無限スクロール
----------------------------*/
@keyframes infinity-scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.section__roll--wrap {
  display: flex;
  overflow: hidden;
}

.section__roll {
  display: flex;
  list-style: none;
  animation: infinity-scroll 100s infinite linear 0.5s both;
  &.slow{
    animation-duration: 500s;
  }
}

.section__roll__item {
  width: calc(100vw / 1.5);
  @include min(){
    width: calc(100vw / 2.5);
  }
}
.section__roll__item > img{
   width: 100%;
}
