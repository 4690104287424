.c-title{
  position: relative;
  @include futura();
  @include font_vw (25 * 0.8, 50 * 0.8);
  line-height: 0.8;
  letter-spacing: 0.15em;
  text-align: center;
  border-bottom: 2px solid #222;
  
  @include max(){
    max-width: calc_rem(528);
    padding-bottom: calc(46 / 50 * 1em);
    margin-left: auto;
    margin-right: auto;
  }
  @include min(){
    @include font_rem(40);
    line-height: calc_rem(70);
    letter-spacing: 0.15em;
    border-bottom-width: 1px;
    padding-bottom: calc_rem(28);
  }
  &:after{
    content: "";
    @include absolute($bottom: 0, $left: 0);
    display: block;
    width: calc(78 / 750 * 100%);
    height: calc_rem(1);
    background-color: #fff;
    @include min(){
      width: calc_rem(50);
    }
  }
  span{
    display: inline-block;
    position: relative;
  }
}