// MV
.mv{
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;
  padding-top: calc(1080 / 1920 * 100%);
  z-index: 1;
  video, figure{
    @include absolute(50%, 50%);
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
  }
  iframe{
    max-width: none;
    //width: 114%;
    position: absolute;
    //top: -205px;
    //left: -135px;
    //height: calc(100% + 418px);

/*
    width: 100%;
    //top: -19%;
    top: -33%;
    left: 0;
    //height: 138%;
    height: 160%;
*/
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
/*    @include max(){
      width: 120%;
      left: -10%;
    }
    */
  }
  img{
    width: 100%;
  }
  .mv__title--wrapper{
    position: absolute;
    top: 67%;
    left: 3%;
    transform: translateY(-50%);
    width: 100%;
    @include min(){
      top: 57%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .mv__title{
    width: math.div(256, 690) * 100%;
    max-width: calc_rem(320);
    @include min(){
      width: 30%;
    }
    img{
      width: 100%;
    }
  }
  .mv__scroll {
    display: none;
    position: absolute;
    right: calc_rem(60);
    bottom: calc_rem(60);
    width: 1px;
    height: 100px;
    @include min(){
      display: block;
      bottom: calc_rem(-30);
    }
    @include min(1401px){
      bottom: calc_rem(60);
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      width: 1px;
      height: 50px;
      background: #eee;
      animation: pathmove 1.4s ease-in-out infinite;
      opacity: 0;
    }
  }
  .sound_button{
    display: block;
    width: calc_vw(33, 66);
    height: calc_vw(33, 66);
    cursor: pointer;
    position: absolute;
    right: calc_rem(30, "sp");
    bottom: calc_rem(210, "sp");
    color: #fff;
    background-size: 100%;
    @include min(){
      width: 55px;
      height: 55px;
      right: calc_rem(170);
      bottom: calc_rem(60);
    }
    &.sound_off{
      background-image: url(../images/sound_on.svg);
    }
    &.sound_on{
      background-image: url(../images/sound_off.svg);
    }
  }
}
// ACCESS
.p-access{
  margin-top: calc_vw(68, 136);
  @include min(){
    margin-top: set_per(200);
  }
  $address-width: 47.5;
  $map-width: 100 - $address-width;

  .c-title{
    position: relative;
    text-align: left;
    border: none;
    &:after{
      content: "";
      @include absolute();
      background-color: #222;
    }
    @include max(){
      margin: 0;
      &:after{
        bottom: -0.1em;
        width: 2.5em;
        height: 2px;
      }
    }
    @include min(){
      &:after{
        bottom: 0.3em;
        width: 1.5em;
        height: 1px;
      }
    }
  }
  .access__address{
    @include max(){
      margin-left: calc(20 / 690 * 100%);
    }
    @include min(){
      width: $address-width + 0%;
      padding-top: 12.3%;
      padding-left: 5.3%;
    }
    .access__address__text{
      @include max(){
        @include font_vw(11, 22);
        letter-spacing: 0.05em;
        margin-top: calc(54 / 690 * 100%);
      }
      @include min(){
        letter-spacing: 0.15em;
        margin-top: calc_rem(20);
        margin-left: calc_rem(8);
      }
    }
    .access__address__icon{
      @include flex($align: center);
      &:before{
        @include max(){
          padding-left: calc(54 / 670 * 100%);
        }
        @include min(){
          padding-left: calc_rem(50);
        }
      }
      + .access__address__icon{
        margin-top: calc_rem(20);
      }
    }
  }
  .access__map{
    position: relative;
    width: 100vw;
    margin-top: calc_vw(26.5, 53);
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    @include min(){
      margin-top: 0;
      margin-bottom: 0;
      margin-left: calc(#{$map-width}% - #{$map-width}vw);
      margin-right: calc(#{$map-width}% - #{$map-width}vw);
      width: #{$map-width}vw;
    }
    .access__map__inner{
      position: relative;
      height: 0;
      padding-top: calc(650 / 1000 * 100%);
    }
    iframe {
      @include absolute(0, 0);
      width: 100%;
      height: 100%;
      filter: grayscale(1);
    }
  }
}
// section共通
.section{
  margin-top: calc(113 / 750 * 100%);
  @include min(){
    margin-top: calc_rem(200);
  }
  .section__content{
    @include max(){
      text-align: center;
    }
    @include min(){
      position: relative;
      //width: 47%;
      max-width: calc_rem(850);
    }
    .p-top & {
      @include min(){
        max-width: calc_rem(540);
      }
    }
    &.auto{
      @include min(){
        margin-left: auto;
        margin-right: auto;
      }
    }
    .btn{
      @include futura();
      letter-spacing: 0.1em;
      border: 2px solid #222;
      @include max(){
        display: inline-block;
        @include font_vw(11, 22);
        line-height: 2.2;
        border-width: 1px;
        padding: 0 1em;
        margin-top: calc(30 / 690 * 100%);
      }
      @include min(){
        @include font_rem(16);
        line-height: 1.875rem;
        position: absolute;
        top: calc_rem(20);
        right: 0;
        border-width: 1px;
        padding: 0 calc_rem(33);
        padding: 0 calc_rem(20);
      }
    }
  }
  .section__text{
    @include max(){
      @include font_vw(11, 22);
      text-align: center;
      line-height: calc(40 / 22);
      letter-spacing: 0.05em;
    }
    @include min(){
      @include futura();
      @include font_rem(14);
      line-height: math.div(26, 14);
      letter-spacing: 0.1em;
      text-align: center;
    }
  }
  .section__text-wrapper{
    @include max(){
      margin-top: calc_vw(40 * 0.9);
    }
    @include min(){
      @include font_rem(14);
      line-height: math.div(26, 14);
      margin-top: calc_rem(35);
    }
  }
  .section__roll{
    @include max(){
      margin-top: calc(60 / 690 * 80%);
    }
    @include min(){
      margin-top: calc_rem(63);
    }
    .section__roll__item{
      //width: 78.5%;
      @include min(){
        //width: 39%;
      }
      img{
        width: 100%;
      }
    }
  }
  .concept__right{
    img{
      width: 100%;
    }
  }
}
// bg
.content__bg{
  position: relative;
  z-index: 0;
  @include max(){
    //padding-top: calc(120 / 750 * 100%);
  }
  @include min(){
    //padding-top: calc_rem(200);
  }
  &:before{
    content: "";
    @include absolute(0, 0);
    display: block;
    width: 100%;
    height: 100%;
    background-image: url(../images/content-bg_sp.png);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: auto;
    z-index: -1;
    @include max(){
      opacity: 0.7;
    }
    @include min(){
      @include absolute(0, 0);
      width: 100%;
      height: 100%;
      background-image: url(../images/content-bg.png);
    }
  }
}

// top
.p-top{
  //MV
  .mv{
    //padding-top: calc(490 / 1200 * 100%);
    //padding-top: calc(580 / 1200 * 100%);

    @include min(){
      //margin-bottom: -13.5%;
    }
  }
  // special
  .special{
    .section__content{
      max-width: 650px !important;
      margin-left: auto;
      margin-right: auto;
    }
    .section__text{
      margin-top: calc_rem(40);
      .mail{
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
  // conecpt
  .concept{
    $left-width: 50;
    $right-width: 100 - $left-width;
    
    .section__content{
      @include min(){
        
      }
      @include min(1508px){
        margin-left: calc_rem(89);
      }
    }
    .concept__right{
      position: relative;
      @include max(){
        margin-top: calc(60 / 690 * 100%);
        margin-left: calc(50% - 50vw);
        margin-right: calc(50% - 50vw);
        width: 100vw;
        }
      @include min(){
        margin-top: 0;
        margin-bottom: 0;
        margin-left: calc(#{$right-width}% - #{$right-width}vw);
        margin-right: calc(#{$right-width}% - #{$right-width}vw);
        width: #{$right-width}vw;
      }
      &:after{
        content: "";
        @include absolute(0, 0);
        display: block;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }
  }
  // APPEARANCE
  .appearance{
    //margin-top: calc(114 / 750 * 100%);
    @include min(){
      margin-top: calc_rem(193);
    }
    .appearance__roll{
      @include min(){
        margin-top: calc_rem(64);
      }
      .appearance__roll__item{
        
      }
    }
  }
  // SUITE
  .suite{
    //margin-top: calc(114 / 750 * 100%);
    @include min(){
      margin-top: calc_rem(190);
    }
    .suite__roll{
      @include min(){
        margin-top: calc_rem(40);
      }
    }
  }
  // GALLERY
  .gallery{
    //margin-top: calc(114 / 750 * 100%);
    @include min(){
      margin-top: calc_rem(193);
    }
    .gallery__roll{
      @include min(){
        margin-top: calc_rem(40);
      }
    }
  }
  // EXPERIENCE
  .experience{
    @include min(){
      margin-top: calc_rem(191);
    }
    .experience__roll{
      @include max(){
        margin-top: calc(63 / 750 * 100%);
      }
      @include min(){
        margin-top: calc_rem(40);
      }
    }
  }
}
// suite
.p-suite{
  .l-container{
    @include min(){
      max-width: calc_rem(1340);
    }
  }
  .section.suite{
    @include min(){
      margin-top: calc_rem(192);
    }
  }
  .section__content{
    @include min(){
      width: 100%;
      max-width: calc_rem(850);
    }
  }
  .suite__box{
    + .suite__box{
      @include max(){
        margin-top: calc(100 / 750 * 100%);
      }
      @include min(){
        margin-top: calc_rem(200);
      }
    }
  }
  .suite__content{
    @include max(){
      padding-left: calc(20 / 690 * 100%);
      padding-right: calc(20 / 690 * 100%);
    }
    @include min(){
      @include flex(between, start);
      margin-top: calc_rem(70);
    }
  }
  .suite__slider{
    @include max(){
      margin-top: calc(50 / 690 * 100%);
      margin-left: auto;
      margin-right: auto;
    }
    @include min(){
      width: 55%;
    }
    img{
      width: 100%;
    }
  }
  .suite__text{
    @include flex(between, start);
    margin-top: 2.3em;
    @include max(){
      justify-content: center;
    }
    @include min(){
      width: 40%;
      max-width: calc_rem(430);
    }
    p{
      font-family: $font-yu-go;
      @include max(){
        width: 44%;
        @include font_vw(11, 22);
        line-height: calc(55 / 22);
      }
      @include min(){
        @include font_rem(16);
        line-height: calc(35 / 16);
      }
    }
  }
  .suite__iamge--wrapper{
    position: relative;
    &:before{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100vw;
      height: 100%;
      margin-left: calc(50% - 50vw);
      margin-right: calc(50% - 50vw);
      background-color: #F9FAE2;
      z-index: -1;
    }
  }
  .suite__iamge{
    text-align: center;
    padding: 20px 0;
    @include min(){
      margin-top: calc_rem(200);
    }
  }
}
// p-appearance
.p-appearance{
  .section__content{
    @include min(){
      width: 100%;
      max-width: calc_rem(850);
    }
  }
}
// coming soon
.p-comingsoon{
  @include max(){
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .comingsoon__content{
    width: 100%;
    height: 0;
    padding-top: calc(500 / 1920 * 100%);
  }
  .comingsoon__inner{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  h2{
    @include futura();
    @include font_rem(20);
    line-height: calc(51 / 40);
    letter-spacing: 0.2em;
  }
  p{
    @include futura();
    @include font_rem(14);
    line-height: calc(40 / 14);
    letter-spacing: 0.05em;
    margin-top: calc_rem(20);
  }
}
// gallery
.p-gallery{

}
