/*
 * html, body
---------------------------------------*/
html, body {
  opacity: 0;
  position: relative;
  background: $color-background;
  font-family: $font-yu-go;
  font-size: #{$font-size-base}px;
  font-weight: 500;
  color: $color-text;
  line-height: 1.75;
  letter-spacing: 0;
  @include transition();
	margin: 0;
	padding: 0;
  @include min(){
    font-family: $font_normal;
  }
}


*{ box-sizing: border-box; }

header, footer {
  width: 100%;
}

a, .hover {
  text-decoration: none;
  @include transition();
  color: inherit;
  &:hover{
    text-decoration: none;
    outline: 0;
    opacity: 0.8;
  }
  &.block{
    display: block;
  }
  &.line{
    display: inline;
  }
  &.link{
    text-decoration: underline;
  }
  img {
    border: 0;
  }
}

ul, li{
  list-style: none;
  padding: 0;
  margin: 0;
}
ul.disc{
  margin-left: 1em;
  li{
    list-style: disc;
  }
}
img {
  border: 0;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

iframe{
  max-width: 100%;
}
picture{
  display: block;
  img{
    width: inherit;
  }
}

/*
 * SP-PC切り替え
---------------------------------------*/
.pc{
  @include max(){
    display: none !important;
  }
}
.sp{
  @include min(){
    display: none !important;
  }
}

/*
 * SPのときだけTELリンクをつける
---------------------------------------*/
a[href*="tel:"] {
  @include min(){
    pointer-events: none;
    cursor: text;
    text-decoration: none;
  }
}

/*
 * 言語切替え
---------------------------------------*/
body.ja{
  .en{
    display: none;
  }
}
body.en{
  .ja{
    display: none;
  }
}