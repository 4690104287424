/*
 * flex-boxのitem幅
---------------------------------------*/
@mixin flex_item($device: ""){
  @if $device != ""{
    $device : "-" + $device;
  }

  .flex_item {
    &#{$device}-col {
      @for $i from 1 through 12 {
        &#{$i} {
          width: (math.div(100, 12) * $i) - 2 * 1%;
        }
      }
    }
  }
}

/*
 * flex-box
---------------------------------------*/
.flex{
  @include flex();
  @include flex_item();
  @include max(){
    @include flex_item("sp");
  }
  @include min(){
    @include flex_item("pc");
  }
  &--pc{
    @include min(){
      @include flex();
      @include flex_item("pc");
    }
  }
  &--sp{
    @include max(){
      @include flex();
      @include flex_item("sp");
    }
  }
  // options
  &-wrap{
    flex-wrap: wrap;
    &--pc{
      @include min(){
        flex-wrap: wrap;
      }
    }
    &--sp{
      @include max(){
        flex-wrap: wrap;
      }
    }
  }
  &-justify{
    &-start{
      justify-content: flex-start;
      &--pc{
        @include min(){
          justify-content: flex-start;
        }
      }
      &--sp{
        @include max(){
          justify-content: flex-start;
        }
      }
    }
    &-end{
      justify-content: flex-end;
      &--pc{
        @include min(){
          justify-content: flex-end;
        }
      }
      &--sp{
        @include max(){
          justify-content: flex-end;
        }
      }
    }
    &-between{
      justify-content: space-between;
      &--pc{
        @include min(){
          justify-content: space-between;
        }
      }
      &--sp{
        @include max(){
          justify-content: space-between;
        }
      }
    }
    &-center{
      justify-content: center;
      &--pc{
        @include min(){
          justify-content: center;
        }
      }
      &--sp{
        @include max(){
          justify-content: center;
        }
      }
    }
  }
  &-align{
    &-start{
      align-items: flex-start;
      &--pc{
        @include min(){
          align-items: flex-start;
        }
      }
      &--sp{
        @include max(){
          align-items: flex-start;
        }
      }
    }
    &-center{
      align-items: center;
      &--pc{
        @include min(){
          align-items: center;
        }
      }
      &--sp{
        @include max(){
          align-items: center;
        }
      }
    }
    &-end{
      align-items: flex-end;
      &--pc{
        @include min(){
          align-items: flex-end;
        }
      }
      &--sp{
        @include max(){
          align-items: flex-end;
        }
      }
    }
    &-stretch{
      align-items: stretch;
      &--pc{
        @include min(){
          align-items: stretch;
        }
      }
      &--sp{
        @include max(){
          align-items: stretch;
        }
      }
    }
  }
  &-row-reverse{
    flex-direction: row-reverse;
    &--pc{
      @include min(){
        flex-direction: row-reverse;
      }
    }
    &--sp{
      @include max(){
        flex-direction: row-reverse;
      }
    }
  }
  &-order{
    @for $i from 1 through 10 {
      &#{$i} {
        order: $i;
      }
    }
  }
}